import router from "@/router";

const topic = {
    cmdTopic: "/RobotOperation/",
    responseTopic: "/RobotResponse/",
    taskFeedbackTopic: "/Robot/taskFeedback/",
    routerInfoTopic: "/RouterStatus/",
    power_cmd: "/RouterCmd/"
};
const cid = {
    start: "ap_start",
    cancel: "ap_cancel",
    resume: "ap_resume",
    pause:  "ap_pause",
    robotInfo: "robotInfo",
    taskFeedBack: "taskFeedBack",
    anonTask: "anonTask",
    routerInfo: "routerInfo"
};

const task_exe_result = {
    CLEAN_TASK_FINISH: 0,
    CLEAN_TASK_SUCCEED: 1,
    CLEAN_TASK_FAILED: -1,  //任务执行失败
    CLEAN_TASK_CANCEL: -2,
    CLEAN_TASK_LOW_BATTERY_FAILED: -3,  // 低电量
    CLEAN_TASK_COULOMETER_ERROR_FAILED: -4,  //库仑计
    CLEAN_TASK_ULTRASONIC_ERROR_FAILED: -5, //超声波
    CLEAN_TASK_IMU_ERROR_FAILED: -6,  //IMU(陀螺仪)传感器
    CLEAN_TASK_SERIAL_PORT_ERROR_FAILED: -7, // 串口
    CLEAN_TASK_HORIZONTAL_LIDAR_ERROR_FAILED: -8, // 水平雷达
    CLEAN_TASK_INCLINED_LIDAR_ERROR_FAILED: -9, // 斜向下雷达
    CLEAN_TASK_FRONT_CAMERA_ERROR_FAILED: -10, //前置rgbd摄像头
    CLEAN_TASK_LEFT_DRIVER_ERROR_FAILED: -11, //左驱动器
    CLEAN_TASK_RIGHT_DRIVER_ERROR_FAILED: -12, //右驱动器
    CLEAN_TASK_MUTIPLE_ERROR_FAILED: -13,// 多个部位异常
    CLEAN_TASK_MIDDLE_BRUSH_ERROR_FAILED: -14,//中刷异常
    CLEAN_TASK_MANUAL_BCHARGING: -15,//后面正在充电
    CLEAN_TASK_EMERGENCY_STOP: - 16,
    CLEAN_TASK_BUMPER_TRIC: -17,
    CLEAN_TASK_NO_DUSTBIN: -18,
    TASK_RESULT_NO_MAP: -19,
    TASK_RESULT_NO_ROBOT_ID: -20,
    TASK_RESULT_LOAD_TASK_ERROR: -21,
    TASK_RESULT_BUSY: -22,
    TASK_RESULT_NO_VALID_TASK: -23,//未找到有效任务
    MAP_LOADING_EXCEPTION: - 24,
    DRIVING_WHEEL_RELEASE_BUTTON_PRESSED: -25,
    INSUFFICIENT_WATER: -26,
    THE_SEWAGE_TANK_IS_FULL: -27,
    FAILED_TO_EXIT_THE_BASE_STATION: -28,
    ABNORMAL_BASE_STATION_POSITION_OR_CHARGING: -29
}
/**
 * 根据任务id获取任务
 *
 * @export
 * @returns {Promise<GetWorkByIdRes>}
 */
export function getTaskResultString(taskResult: number, that: any) {
    let taskResultStr = "";
        switch(taskResult){
            case  task_exe_result.CLEAN_TASK_SUCCEED: {
                taskResultStr = that.$t("CLEAN_TASK_SUCCEED")
                break
            }
            case  task_exe_result.CLEAN_TASK_FAILED: {
                taskResultStr = that.$t("CLEAN_TASK_FAILED")
                break
            }//任务执行失败
            case  task_exe_result.CLEAN_TASK_CANCEL: {
                taskResultStr = that.$t("CLEAN_TASK_CANCEL")
                break
            }
            case task_exe_result.CLEAN_TASK_LOW_BATTERY_FAILED: {
                taskResultStr = that.$t("CLEAN_TASK_LOW_BATTERY_FAILED")
                break
            }  // 低电量
            case task_exe_result.CLEAN_TASK_COULOMETER_ERROR_FAILED: {
                taskResultStr = that.$t("CLEAN_TASK_COULOMETER_ERROR_FAILED")
                break
            }  //库仑计
            case task_exe_result.CLEAN_TASK_ULTRASONIC_ERROR_FAILED: {
                taskResultStr = that.$t("CLEAN_TASK_ULTRASONIC_ERROR_FAILED")
                break
            } //超声波
           case task_exe_result.CLEAN_TASK_IMU_ERROR_FAILED: {
                taskResultStr = that.$t("CLEAN_TASK_IMU_ERROR_FAILED")
                break
           }  //IMU(陀螺仪)传感器
           case task_exe_result.CLEAN_TASK_SERIAL_PORT_ERROR_FAILED: {
                taskResultStr = that.$t("CLEAN_TASK_SERIAL_PORT_ERROR_FAILED")
                break
           } // 串口
           case task_exe_result.CLEAN_TASK_HORIZONTAL_LIDAR_ERROR_FAILED: {
                taskResultStr = that.$t("CLEAN_TASK_HORIZONTAL_LIDAR_ERROR_FAILED")
                break
           } // 水平雷达
           case task_exe_result.CLEAN_TASK_INCLINED_LIDAR_ERROR_FAILED: {
                taskResultStr = that.$t("CLEAN_TASK_INCLINED_LIDAR_ERROR_FAILED")
                break
           } // 斜向下雷达
           case task_exe_result.CLEAN_TASK_FRONT_CAMERA_ERROR_FAILED: {
                taskResultStr = that.$t("CLEAN_TASK_FRONT_CAMERA_ERROR_FAILED")
                break
           } //前置rgbd摄像头
           case task_exe_result.CLEAN_TASK_LEFT_DRIVER_ERROR_FAILED: {
                taskResultStr = that.$t("CLEAN_TASK_LEFT_DRIVER_ERROR_FAILED")
                break
           } //左驱动器
           case task_exe_result.CLEAN_TASK_RIGHT_DRIVER_ERROR_FAILED: {
                taskResultStr = that.$t("CLEAN_TASK_RIGHT_DRIVER_ERROR_FAILED")
                break
           } //右驱动器
           case task_exe_result.CLEAN_TASK_MUTIPLE_ERROR_FAILED: {
            taskResultStr = that.$t("CLEAN_TASK_MUTIPLE_ERROR_FAILED")
            break
           }// 多个部位异常
           case task_exe_result.CLEAN_TASK_MIDDLE_BRUSH_ERROR_FAILED: {
                taskResultStr = that.$t("CLEAN_TASK_MIDDLE_BRUSH_ERROR_FAILED")
                break
           }//中刷异常
           case task_exe_result.CLEAN_TASK_MANUAL_BCHARGING: {
                taskResultStr = that.$t("CLEAN_TASK_MANUAL_BCHARGING")
                break
           }//后面正在充电
           case task_exe_result.CLEAN_TASK_EMERGENCY_STOP: {
                taskResultStr = that.$t("CLEAN_TASK_EMERGENCY_STOP")
                break
           }
           case task_exe_result.CLEAN_TASK_BUMPER_TRIC: {
                taskResultStr = that.$t("CLEAN_TASK_BUMPER_TRIC")
                break
           }
           case task_exe_result.CLEAN_TASK_NO_DUSTBIN: {
                taskResultStr = that.$t("CLEAN_TASK_NO_DUSTBIN")
                break
           }
           case task_exe_result.TASK_RESULT_NO_MAP: {
                taskResultStr = that.$t("TASK_RESULT_NO_MAP")
                break
           }
           case task_exe_result.TASK_RESULT_NO_ROBOT_ID: {
                taskResultStr = that.$t("TASK_RESULT_NO_ROBOT_ID")
                break
           }
           case task_exe_result.TASK_RESULT_LOAD_TASK_ERROR: {
                taskResultStr = that.$t("TASK_RESULT_LOAD_TASK_ERROR")
                break
           }
           case task_exe_result.TASK_RESULT_BUSY: {
                taskResultStr = that.$t("TASK_RESULT_BUSY")
                break
           }
           case task_exe_result.TASK_RESULT_NO_VALID_TASK: {
                taskResultStr = that.$t("TASK_RESULT_NO_VALID_TASK")
                break
           }//未找到有效任务
           case task_exe_result.MAP_LOADING_EXCEPTION:{
                taskResultStr = that.$t("MAP_LOADING_EXCEPTION")
                break
           }
           case task_exe_result.DRIVING_WHEEL_RELEASE_BUTTON_PRESSED: {
                taskResultStr = that.$t("DRIVING_WHEEL_RELEASE_BUTTON_PRESSED")
                break
           }
           case task_exe_result.INSUFFICIENT_WATER: {
                taskResultStr = that.$t("INSUFFICIENT_WATER")
                break
           }
           case task_exe_result.THE_SEWAGE_TANK_IS_FULL: {
                taskResultStr = that.$t("THE_SEWAGE_TANK_IS_FULL")
                break
           }
           case task_exe_result.FAILED_TO_EXIT_THE_BASE_STATION:  {
                taskResultStr = that.$t("FAILED_TO_EXIT_THE_BASE_STATION")
                break
           }
           case task_exe_result.ABNORMAL_BASE_STATION_POSITION_OR_CHARGING:{
                taskResultStr = that.$t("ABNORMAL_BASE_STATION_POSITION_OR_CHARGING")
                break
           }
        }
    return taskResultStr;
}

export {
    topic,
    cid,
    task_exe_result
};