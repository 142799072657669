import * as mqtt from "mqtt";
import { User } from "@/api/user";
import router from '@/router/index';
import { Dialog, Toast } from 'vant';
import { EventBus } from "@/utils/eventBus";
import { Component, Vue } from "vue-property-decorator";
import { globalData } from "@/utils/config";
import {setPushTags} from "@/utils/appUtil";
import {topic, cid} from "@/utils/mqttUtil";
@Component({
    components: {
        [Dialog.Component.name]: Dialog.Component
    },
})
export default class MqttClientM extends Vue {
    private static instance: MqttClientM; //类型为这个类
    // mqtt客户端
    private mqttClient: any;
    private mqttHost: string = "api.movexbot.com" //119.23.75.4   api.movexbot.com  测试： test.movexbot.com 调试 192.168.0.31
    private mqttPort: string = "8088" //8088 8083
    private topicUserPrefix: string = '/User/Login/';
    private topicExportPrefix: string = '/User/exportTask/';
    private topicRobotPrefix: string = '/Robot/robotStat/';
    private context: any;
    private responseDatas:any = {};
    // *static 会将它挂载类上，而不是实例上
    static getInstance() {
        if (!this.instance) {
            this.instance = new MqttClientM();
        }
        return this.instance;
    }
    public init(tip: string, confirm: string, context: any) {
        if (this.mqttClient != null) {
            return
        }
        const that = this
        this.context = context;
        const host = "wss://" + this.mqttHost + ":" + this.mqttPort + "/mqtt"; // wss
        const userInfoStr: string | null = localStorage.getItem("userInfo");
        if (userInfoStr == null) {
            return;
        }
        console.log("userInfoStr:" + userInfoStr)
        const userInfo: User = JSON.parse(userInfoStr);
        const id = userInfo.id
        this.mqttClient = mqtt.connect(host, {
            connectTimeout: 40000,
            clientId: userInfo.username + "_wbapp_" + new Date().getTime(),
            clean: true,
            username: "robot",
            password: "robot123",
        });
        this.mqttClient.on("connect", function () {
            console.log("connect success");
            const topic = that.topicUserPrefix + id;
            that.mqttClient.subscribe(topic, { qos: 0 }, function (err: any, granted: any) {
                if (!err) {
                    console.log("subscribe topic:", granted);
                }
            });
            const topic1 = that.topicExportPrefix + id;
            that.mqttClient.subscribe(topic1, { qos: 0 }, function (err: any, granted: any) {
                if (!err) {
                    console.log("subscribe topic: ", granted);
                }
            });
        });

        this.mqttClient.on("error", function (error: any) {
            console.log(error);
        });

        this.mqttClient.on("unSubscribed", function () {
            console.log("cancel subscribe");
        });
        this.mqttClient.on("message", function (topic1: any, message: any) {
            // console.log('message', message.toString())
            if (topic1.indexOf(that.topicUserPrefix) != -1) {
                const loginTimeStr: string | null = localStorage.getItem("loginTime");
                console.log(loginTimeStr)
                if (loginTimeStr != null && (Number(message.toString()) - Number(loginTimeStr) > 0)) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('userInfo')
                    localStorage.removeItem('loginTime')
                    setPushTags([]);
                    that.uninit();
                    Dialog.alert({
                        message: tip,
                        confirmButtonText: confirm,
                        showConfirmButton: true,
                        theme: 'round-button',
                        confirmButtonColor: '#4E8AE9'
                    }).then(() => {
                        router.replace({
                            path: '/login'
                        })
                        // on close
                    });
                }
            } else if (topic1.indexOf(that.topicRobotPrefix) != -1) {
                const robotInfo =  JSON.parse(message.toString())
                robotInfo.cid = cid.robotInfo
                // console.log('msg robotInfo', robotInfo)
                EventBus.$emit("robotDetail", robotInfo);
            } else if (topic1.indexOf(topic.routerInfoTopic) != -1) {
                const routerInfo =  JSON.parse(message.toString())
                routerInfo.cid = cid.routerInfo
                // console.log('msg robotInfo', robotInfo)
                EventBus.$emit("robotDetail", routerInfo);
            } else if (topic1.indexOf(topic.taskFeedbackTopic) != -1) {
                const feedBack =  JSON.parse(message.toString())
                feedBack.cid = cid.taskFeedBack
                // console.log('msg feedBack', feedBack)
                EventBus.$emit("robotDetail", feedBack);
            }  else if (topic1.indexOf(that.topicExportPrefix) != -1) {
                if (message) {
                    const json: any = JSON.parse(message);
                    const fileName: string = json.fileName
                    const successCount: number = json.successCount
                    const totalCount: number = json.totalCount
                    //globalData.exportFileUrl + fileName;
                    // Toast(globalData.exportFileUrl + fileName + '99999')
                    context.linkText = globalData.exportFileUrl + fileName;
                    Toast(context.$t("exported_report_to_mailbox").toString());
                  }
            } else if(topic1.indexOf(topic.responseTopic) != -1) {
                const reponse =  JSON.parse(message.toString())
                EventBus.$emit("robotDetail", reponse);
            } 
        });
    }

    public unSubscribeRobot(robot: string) {
        this.unsubscribe(this.topicRobotPrefix + robot)
    }
    public subscribeRobot(robot: string) {
        this.subscribe(this.topicRobotPrefix + robot)
    }
    public subscribeResponse(robot: string) {
        this.subscribe(topic.responseTopic + robot)
    }
    public subscribeFeedback(robot: string) {
        this.subscribe(topic.taskFeedbackTopic + robot)
    }
    public unSubscribeFeedback(robot: string) {
        this.unsubscribe(topic.taskFeedbackTopic + robot)
    }
    public unSubscribeResponse(robot: string) {
        this.unsubscribe(topic.responseTopic + robot)
    }

    public unSubscribeRouterInfo(robot: string) {
        this.unsubscribe(topic.routerInfoTopic + robot)
    }
    public subscribeRouterInfo(robot: string) {
        this.subscribe(topic.routerInfoTopic + robot)
    }
    public publishMessage (topic: string, message: any) {
        if (this.mqttClient == null) {
            console.log('startTask CCCCCC')
            return
        }
       // this.$set(this.responseDatas, cid, reponse)
       console.log(" responseDatas topic:" , topic);
        console.log(" responseDatas message:" , message);
        this.mqttClient.publish(topic, message)
    }
    private subscribe(topic: string) {
        console.log('subscribe topic:' + topic)
        if (this.mqttClient == null) {
            return
        }
        this.mqttClient.subscribe(topic, { qos: 0 }, function (err: any, granted: any) {
            if (!err) {
              //  console.log("subscribe topic:" + granted[0].topic);
            }
        });
    }
    private onMessage(cid: string, message: string){
        console.log('responseDatas[json.cid]', this.responseDatas)
        this.responseDatas[cid](message)
    }
    private unsubscribe(topic: string) {
        if (this.mqttClient == null) {
            return
        }
        this.mqttClient.unsubscribe(topic, { qos: 0 }, function (err: any, granted: any) {
            if (!err) {
                console.log("unsubscribe topic:," + granted);
            }
        });
    }

    public uninit() {
        if (this.mqttClient != null) {
            this.mqttClient.end();
            this.mqttClient = null;
        }
    }
}