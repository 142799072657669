import request from '@/utils/request';
import { CommonEmailRes, CommonRes } from './common';

/**
 * 用户结构体
 */
export interface User {
    id: number;
    username: string;
    phone: string;
    createTime: string;
    type?: any;
    userType: number;
    pushMessage?: string;
}
export interface GetUserInfoRes {
    msg: string;
    code: number;
    user: UserInfo;
}
/**
 * 用户结构体
 */
export interface UserInfo {
    id: number;
    username: string;
    phone: string;
    createTime: string;
    companyCode: string;
}
export interface LoginRes {
    msg: string;
    code: number;
    expire: number;
    user: User;
    token: string;
    loginTime: number;
}

export interface LoginReq {
    account: string;
    password: string;
    pushId: string;
    languageCode: string;
}

export interface RegisterReq {
    username: string;
    email: string;
    password: string;
    name: string;
    companyCode: string;
    code: string;
}

export interface UpdatePasswordReq {
    oldPassword: string,
    password: string;
}

export interface SendSMSReq {
    email: string;
    type?: string;
    companyType:string;
}

export interface updatePasswordByEmailReq {
    email: string;
    password: string;
    code: string;
}

/**
 * 登录
 *
 * @export
 * @returns {Promise<LoginRes>}
 */
export function login(params: LoginReq): Promise<LoginRes> {
    return request.post<LoginRes>('/login', params);
}

/**
 * 登出
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function logout(): Promise<CommonRes> {
    return request.post<CommonRes>('/logout', null);
}

/**
 * 用户注册
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function register(params: RegisterReq): Promise<CommonRes> {
    return request.post<CommonRes>('/user', params);
}

/**
 * 用户修改密码
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function updatePassword(params: UpdatePasswordReq): Promise<CommonRes> {
    return request.patch<CommonRes>('/user/password', params);
}

/**
 * 发送邮件验证码
 *
 * @export
 * @returns {Promise<CommonEmailRes>}
 */
 export function sendSMS(params: SendSMSReq): Promise<CommonEmailRes> {
    return request.post<CommonEmailRes>('/user/email/code' , params);
}

/**
 * 根据email code修改密码
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function updatePasswordByEmail(params: updatePasswordByEmailReq): Promise<CommonRes> {
    return request.patch<CommonRes>('/user/password_by_code', params);
}

/**
 * 获取用户信息
 *
 * @export
 * @returns {UserInfo}
 */
export function getUserInfo(): Promise<GetUserInfoRes> {
    return request.get<GetUserInfoRes>('/user');
}